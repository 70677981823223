import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import Suggestions from './Suggestions'
import ChatDisplay from './ChatDisplay'
import ChatInput from './ChatInput'
import ErrorModal from './ErrorModal'
import type { Message } from '../types/Message'
import MixpanelUtil from '../mixpanel'
import Api from '../api'

const mp = MixpanelUtil.getInstance()
interface ChatProps {
	displayName?: string
	spreadsheetId: string
}

const api = Api.getInstance()

const Chat: React.FC<ChatProps> = ({ displayName, spreadsheetId }: ChatProps) => {
	// Backend
	const [conversationId, setConversationId] = useState('')
	const [isErrorShowing, setIsErrorShowing] = useState(false)
	const [latestUserMessageId, setLatestUserMessageId] = useState('')
	const [abortController, setAbortController] = useState(() => new AbortController())

	const chatDisplayRef = useRef<HTMLDivElement>(null)

	// Display
	const [messages, setMessages] = useState<Message[]>([])
	const [suggestions, setSuggestions] = useState<Object[]>([])
	const [isThumbsShowing, setIsThumbsShowing] = useState(false)
	const [requestInFlight, setRequestInFlight] = useState('')
	const [input, setInput] = useState({ content: '', type: '' })
	const [errorMessage, setErrorMessage] = useState('')
	const [isAtBottom, setIsAtBottom] = useState(true)

	useEffect(() => {
		getLocalSuggestions().then((suggestions) => setSuggestions(suggestions))
	}, [])

	useEffect(() => {
		if (messages.length === 0) {
			setMessages([initialMessage(displayName)])
		}
	}, [messages, displayName])

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		// Reset state if spreadsheetId changes
		setConversationId('')
		setMessages([initialMessage(displayName)])
		getLocalSuggestions().then((suggestions) => setSuggestions(suggestions))
		setLatestUserMessageId('')
		setIsThumbsShowing(false)
		setRequestInFlight('')
	}, [spreadsheetId, displayName])

	// Scroll to the bottom when view changes
	// Not best practice with the dependency array
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (isAtBottom && chatDisplayRef.current) {
			chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight
		}
	}, [suggestions, isThumbsShowing])

	const initialMessage = (displayName?: string): Message => ({
		role: 'assistant',
		content: `${displayName ? `Hi ${displayName}!` : 'Hi!'} I'm Rosie, your AI spreadsheet assistant. I can assist with:

- Interpreting formulas
- Explaining model structure
- Planning new analyses
- Any other questions you have

What can I help you with today?`,
		needsSync: false,
	})

	const getLocalSuggestions = async () => {
		return [
			{
				type: 'structure',
				content: 'How is this spreadsheet structured?',
			},
			{
				type: 'formula',
				content: 'Can you write a formula to do something interesting with this data?',
			},
		]
	}

	const handleUpdateInput = (newInput: string) => {
		if (!input.content && newInput) mp.track('Query input begin typing')
		setInput((prevInput) => {
			return {
				type: 'user',
				content: newInput,
			}
		})
	}

	const handleSuggestionSelected = (suggestion) => {
		setInput(suggestion)
	}

	const requestInFlightRef = useRef<string>(requestInFlight)
	const suggestionsRef = useRef<any>(suggestions)

	useEffect(() => {
		requestInFlightRef.current = requestInFlight
	}, [requestInFlight])

	useEffect(() => {
		suggestionsRef.current = suggestions
	}, [suggestions])

	const setErrorOutput = (errorOutput: string) => {
		setErrorMessage(errorOutput)
		setIsErrorShowing(!!errorOutput) // Show the error dialog if there is an error
	}

	const closeErrorDialog = () => {
		setIsErrorShowing(false)
	}

	const onCancelMessage = () => {
		mp.track('Query response stopped')
		abortController.abort('stopped by user')
		setAbortController(new AbortController())
		setRequestInFlight('')
	}

	const handleInput = async () => {
		if (!input.content || !spreadsheetId) {
			return
		}
		if (requestInFlight) {
			setErrorOutput('Please wait while we process your request.')
			return
		}

		const start = performance.now()
		mp.track('Query', {
			query: input.content,
			conversationId: conversationId,
		})
		const message = {
			role: 'user',
			content: input.content,
			needsSync: false,
		}

		setIsThumbsShowing(false)
		setSuggestions([])
		setMessages((prevState) => [...prevState, message])
		setRequestInFlight('Thinking')

		// Create conversation if necessary
		let convId = conversationId
		if (!conversationId) {
			const convResult = await api.createConversation(spreadsheetId)
			if (!convResult.response.ok) {
				setErrorOutput(convResult.errorString || '')
				setRequestInFlight('')
				mp.track('ERROR: creating conversation', convResult)
				return
			} else {
				convId = convResult.body
				setConversationId(convId)
			}
		}

		try {
			await postMessagesWrapper(convId, message, input.type, abortController)
			setIsThumbsShowing(true)
		} catch (error) {
			mp.track('ERROR: POST messages wrapper', error)
		} finally {
			setRequestInFlight('')
		}
		const duration = performance.now() - start
		datadogLogs.logger.info('response_end', {
			duration_ms: duration,
			spreadsheet_id: spreadsheetId,
			conversation_id: conversationId,
		})
		await updateSuggestions(convId)
	}

	const updateSuggestions = async (conversationId: string) => {
		const suggestionsResponse = await api.fetchSuggestions(conversationId, spreadsheetId)

		if (
			Array.isArray(suggestionsResponse) &&
			suggestionsResponse.length > 0 &&
			!requestInFlightRef.current
		) {
			// Don't replace explain formula suggestion, end up with 2 suggestions
			const newSuggestions: Object[] = []
			let newSuggestionsIndex = 0
			for (let i = 0; i < 2; i++) {
				if (
					suggestionsRef.current.length > i &&
					suggestionsRef.current[i].type === 'explain_formula'
				) {
					newSuggestions.push(suggestionsRef.current[i])
				} else {
					newSuggestions.push(suggestionsResponse[newSuggestionsIndex])
					newSuggestionsIndex++
				}
			}
			setSuggestions(newSuggestions)
		}
	}

	const streamMessageDelta = (delta: string, messageId: string) => {
		setMessages((prevState) => {
			const i = prevState.findIndex((m) => m.id === messageId)
			if (i === -1) {
				mp.track('ERROR: Could not find message to update')
				return prevState
			}
			const newMessages = [...prevState]
			newMessages[i].content = newMessages[i].content + delta
			return newMessages
		})
	}

	// Listener that handles all events
	const listener = (start: number) => (event) => {
		let data
		try {
			data = JSON.parse(event.data)
		} catch (e) {
			data = event.data
		}
		switch (event.event) {
			case 'message_created': {
				const duration = performance.timeOrigin + performance.now() - start
				datadogLogs.logger.info('response_first_token', {
					duration_ms: duration,
					message_id: data.id,
					spreadsheet_id: spreadsheetId,
					conversation_id: conversationId,
				})
				const newMessage = {
					id: data.id,
					role: 'assistant',
					content: '',
					needsSync: true,
				}
				setMessages((prevState) => [...prevState, newMessage])
				break
			}

			case 'message_completed': {
				mp.track('Response ', { message: data.content })
				setMessages((prevState) => {
					const i = prevState.findIndex((m) => m.id === data.id)
					if (i === -1) {
						mp.track('ERROR: Could not find message to update')
						return prevState
					}
					const msg = data

					const newMessages = [...prevState]
					newMessages[i] = msg
					return newMessages
				})
				break
			}

			case 'step_created': {
				if (data?.type === 'tool_calls') setRequestInFlight('Step')
				break
			}

			case 'step_completed': {
				setRequestInFlight('Thinking')
				setMessages((prevState) => {
					const newMessages = [...prevState]
					newMessages.push(data)
					return newMessages
				})
				break
			}

			case 'thread.message.delta':
				streamMessageDelta(data.delta.content[0].text.value, data.id)
				break

			case 'stream': {
				streamMessageDelta(data.delta, data.id)
				break
			}

			case 'user_message_id':
				setLatestUserMessageId(event.data)
				break
			case 'assumptions':
				mp.track('Assumptions not implemented')
				break
			case 'pivot_table_creation': {
				setMessages((prevState) => {
					const creation_message: Message = {
						role: 'assistant',
						content: data.response,
						pivotTableCreation: data,
						needsSync: true,
					}
					return [...prevState, creation_message]
				})
				break
			}
			case 'end':
				if (data?.response?.status >= 400) {
					mp.track('ERROR: POST Conv', { message: data.errorString })
					setErrorOutput(data.errorString)
				}
				mp.track('Response complete')
				break
			case 'error':
				setErrorOutput('Sorry, something broke. Please try again later.')
				break
			default:
		}
	}

	const postMessagesWrapper = async (convId, message, query_type, abortController) => {
		const metadata = {
			spreadsheet_id: spreadsheetId,
			title: '',
			activeSheetTitle: '',
		}
		const start = performance.timeOrigin + performance.now()
		return await api.postConversation(
			convId,
			metadata,
			message,
			query_type,
			listener(start),
			abortController,
		)
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
			<ChatDisplay
				ref={chatDisplayRef}
				isAtBottom={isAtBottom}
				setIsAtBottom={setIsAtBottom}
				messages={messages}
				responseGenerationState={requestInFlight}
				isThumbsShowing={isThumbsShowing}
				conversationId={conversationId}
				latestUserMessageId={latestUserMessageId}
			/>
			<Suggestions suggestions={suggestions} onItemSelect={handleSuggestionSelected} />
			<ChatInput
				inputText={input.content}
				isRequestInFlight={!!requestInFlight}
				onUpdateMessage={handleUpdateInput}
				onSendMessage={handleInput}
				onCancel={onCancelMessage}
			/>

			<ErrorModal
				isOpen={isErrorShowing}
				onClose={closeErrorDialog}
				errorMessage={errorMessage}
			/>
		</div>
	)
}

export default Chat
